import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const Heading = styled.h1`
  font-size: 6rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

const ComingSoonPage = () => {
  return (
    <Container>
      <Heading>Coming Soon</Heading>
    </Container>
  );
};

export default ComingSoonPage;
