import React from 'react';
import ComingSoonPage from './ComingSoonPage';

const App = () => {
  return (
    <div>
      <ComingSoonPage />
    </div>
  );
};

export default App;
